import template from './dialog.html';
import FlowModel from './../../lib/models/Flow.js'

class AddEditFlowVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.flow = ko.observable(new FlowModel(this.dialog.bindings.flow));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.flow().save();
		if (res)
		{
			if (res?.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}
}

class AddEditFlowDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditFlowVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditFlow'; // Unique dialog name
	}
}

export default {
	name: 'AddEditFlow',
	dialog_class: AddEditFlowDialog,
	provider: 'ps',
	template: template
}