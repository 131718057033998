import template from './flow-rules.html';

class FlowRulesViewModel
{
	constructor(params)
	{
		this.params = params;
		this.flow_uuid = ko_helper.safe_observable(params.flow_uuid);
		this.flow_rules = ko_helper.safe_observableArray(params.flow_rules);
		this.flow_states = ko.observableArray();

		this.init();
	}

	async init()
	{
		this.fetchFlowStates();
	}

	async addRule()
	{
		this.flow_rules.push({
			to_flow_state_qname: ko.observable(''),
			flow_rule_description: ko.observable(''),
			flow_rule_options: ko.observable('')
		});
	}

	async fetchFlowStates()
	{
		const Rules = await Grape.cache.get('FlowStatesLookup');
		let rules = Rules.filter((x) => x.flow_uuid === this.flow_uuid()).map(rule => {return rule.flow_state_qname});
		this.flow_states(rules);
	}

	async deleteRule(index)
	{
		this.flow_rules.splice(index, 1);
	}
}

export default {
	name: 'flow-rules',
	viewModel: FlowRulesViewModel,
	module_type: 'ko',
	template: template
};