/**
 * @kind cache
 * @class FlowStatesLookup
 * @description Custom lookup cache for all available flow states
 * @usage Grape.cache.fetch('FlowStatesLookup', function(data) { });
 */
export default {
	name: 'FlowStatesLookup',
	options: {
		schema: 'states',
		table: 'v_flow_states'
	}
};
