class StateModel 
{
	constructor({state_uuid,flow_uuid,flow_name,flow_namespace,state,state_description,state_options})
	{
		this.flow_uuid = ko_helper.safe_observable(flow_uuid);
		this.state_uuid = ko_helper.safe_observable(state_uuid);
		this.flow_name = ko_helper.safe_observable(flow_name);
		this.flow_namespace = ko_helper.safe_observable(flow_namespace);
		this.state = ko_helper.safe_observable(state);
		this.state_description = ko_helper.safe_observable(state_description);
		this.state_options = ko_helper.safe_observable(state_options);

		if (typeof this.state_options() === 'object')
			this.state_options(JSON.stringify(this.state_options()));
	}

	async save(flow_rules)
	{
		if (!this.state())
			Grape.alerts.alert({type: 'warning', message: 'State name cannot be empty!'});
		else
		{
			let payload = this.serialize(ko.utils.unwrapObservable(flow_rules));
			if (typeof payload.state_options === 'string')
			{
				try {
					payload.state_options = JSON.parse(payload.state_options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}
			return Grape.fetches.postJSON('/api/states-config/states', [payload]);
		}
	}

	async delete()
	{
		return Grape.fetches.postJSON('/api/states-config/states/delete', {state_uuid: this.state_uuid()});
	}

	serialize(flow_rules)
	{
		for (var i = 0; i < flow_rules.length; i++) 
		{
			if(typeof flow_rules[i].to_flow_state_qname() === 'undefined')
				flow_rules.splice(i, 1);
		}

		let to_flow_rules = flow_rules.map(to_flow_rule => {
			if (typeof to_flow_rule.flow_rule_options() === 'string')
			{
				try {
					to_flow_rule.flow_rule_options = JSON.parse(to_flow_rule.flow_rule_options());
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

			return {
				to_flow_state_qname: to_flow_rule.to_flow_state_qname(),
				flow_rule_description: to_flow_rule.flow_rule_description(),
				flow_rule_options: to_flow_rule.flow_rule_options
			}
		});

		return {
			state_uuid: this.state_uuid(),
			flow_qname: `${this.flow_namespace()}.${this.flow_name()}`,
			state: this.state(),
			state_description: this.state_description(),
			state_options: this.state_options(),
			to_flow_rules: to_flow_rules
		};
	}

}

export default StateModel;