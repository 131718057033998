import template from './page.html';

class StatesConfigViewModel
{
	constructor(page) {
		this.page = page;
		this.element = page.element;

		this.lookupValueListOptions = ko.observable({filter:[{field:'schema', operator:'=', value:''}]});
		this.selectedFlow = ko.observable();
		this.selectedFlow.subscribe((newValue)=>{
			this.lookupValueListOptions({filter:[
				{field:'flow_name', operator:'=', value:newValue.flow_name},
			]});
		});
		this.selectedLookupValue = ko.observable();
	}
}

class StatesConfigClass
{
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new StatesConfigViewModel(this);
		this.name = 'StatesConfig';

	}

	async init() {
	}

	async updateData() {
	}

	async teardown() {
	}
}

export default {
	route: '[/]ui/states-config',
	page_id: '[/]ui/states-config',
	page_class: StatesConfigClass,
	template: template,
	title: 'State Config',
	icon: 'fa fa-files',
	idx: 10
}
