import template from './dialog.html';
import StateModel from './../../lib/models/State.js'

class AddEditStateVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;	
        this.state = ko.observable(new StateModel(this.dialog.bindings.state));
		this.state.subscribe(async () => {
			await this.fetchFlowRules();
		});
		this.flow_rules = ko.observableArray();
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.state().save(this.flow_rules());
		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}

	async fetchFlowRules()
	{
		let result = await Grape.fetches.getJSON('/api/record', 
			{
				schema: 'states',
				table: 'v_flow_rules',
				filter: [{field: 'state_uuid', operator: '=', value: this.state().state_uuid}]
			});

		if (result.records.length > 0) 
		{
			let rules = result.records.map(rule => {
				return {
					to_flow_state_qname: ko.observable(rule.to_flow_state.flow_state_qname),
					flow_rule_description: ko.observable(rule.flow_rule_description),
					flow_rule_options: ko.observable(JSON.stringify(rule.flow_rule_options))
				}
			});
			this.flow_rules.push(...rules);
		}
	}
}

class AddEditStateDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditStateVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditState'; // Unique dialog name
	}

	async init ()
	{
		await this.viewModel.fetchFlowRules();
	}
}

export default {
	name: 'AddEditState',
	dialog_class: AddEditStateDialog,
	provider: 'ps',
	template: template
}
