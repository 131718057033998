import template from './state-list.html';

class StateListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.statesTable = ko_helper.safe_observable(params.statesTable);
		this.selectedState = ko_helper.safe_observable(params.selectedState);
		this.stateListOptions = ko_helper.safe_observable(params.stateListOptions || {});
		this.stateListOptions.subscribe((newValue)=>{
			this.init();
		});
		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'state_uuid'
			},
			{
				name: 'state'
			},
			{
				name: 'state_description'
			},
			{
				name: 'state_options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init(){
		let options = {};
		if (window.localStorage.getItem('state.state-list.state-list-options'))
			options = JSON.parse(window.localStorage.getItem('state.state-list.state-list-options'));
		else
			options = {fields: ['state_uuid','flow_uuid','flow_name','flow_namespace','state','state_description','state_options']};

		options = Object.assign(options, this.stateListOptions());

		const list = [];
		for (let field of this.table_fields())
		{
			if (!field.name)
				continue;
			const newfield = {
				name: field.name,
				visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
			};

			if (field.formatter){
				if (field.formatter == 'json')
					newfield.formatter = x => JSON.stringify(x);
			}

			list.push(newfield);
		}
		this.fields(list);

		if (options.sortfield)
			this.sortfield(options.sortfield);
		if (options.sortorder)
			this.sortorder(options.sortorder);
		if (options.filter)
			this.filters(options.filter);
	}

	async update() {
		this.fetchFlowState();
	}

	async fetchFlowState(){
		const options = this.serializeOptions();
		const result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		const fields = ['state_uuid','flow_uuid','flow_name','flow_namespace','state','state_description','state_options'];

		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		const filters = [];

		for (let f of Object.assign(this.filters(), this.stateListOptions().filter||[]))
			filters.push({field: f.field, operator: f.operator, value: f.value});

		const options = {
			schema: 'states',
			table: 'v_flow_states',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('state.state-list.state-list-options', JSON.stringify(options));

		return options;
	}

	async editState(data, event){
		let res = await Grape.dialog.open('AddEditState', {state:data, edit: true});

		if (res?.status === 'OK'){
			this.update();
			this.selectedState('');
		}
	}

	async upsertTableState(){
		let flow_name = this.statesTable()?.flow_name || '';
		let flow_namespace = this.statesTable()?.flow_namespace || '';

		let res = await Grape.dialog.open('AddEditState', {
			state:{
				flow_name,
				flow_namespace
			}
		});
		if (res?.status === 'OK'){
			this.update();
		}
	}

	async deleteState(data){
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete State <b>(${data.state})</b>?`, cancel_type:'default', accept_type:'warning'});

		if (confirm){
			let res = await Grape.fetches.postJSON('/api/states-config/states/delete', {state_uuid:data.state_uuid});
			console.log(res);
			if (res?.status === 'OK'){
				this.update();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'state-list',
	viewModel: StateListViewModel,
	module_type: 'ko',
	template: template
};