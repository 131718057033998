class FlowModel
{
	constructor({flow_uuid, flow_namespace, flow_name, flow_description, flow_options})
	{
		this.flow_uuid = flow_uuid;
		this.flow_namespace = flow_namespace;
		this.flow_name = flow_name;
		this.flow_description = flow_description;
		this.flow_options = flow_options;

		if (typeof this.flow_options === 'object')
			this.flow_options(JSON.stringify(this.flow_options));
	}

	async save()
	{
			let payload = this.serialize();
			
			if (typeof payload.flow_options === 'string')
			{
				try {
					payload.flow_options = JSON.parse(payload.flow_options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

			return Grape.fetches.postJSON('api/states-config/flow', payload);
	}

	async delete()
	{
		return Grape.fetches.postJSON('/api/states-config/flow/delete', {flow_uuid: this.flow_uuid});
	}

	serialize()
	{
		return {
			flow_uuid: this.flow_uuid,
			flow_namespace: this.flow_namespace,
			flow_name: this.flow_name,
			flow_description: this.flow_description,
			flow_options: this.flow_options
		};
	}
}

export default FlowModel;