
import template from './flow-list.html';

class FlowListViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
		this.selectedFlow = ko_helper.safe_observable(params.selectedFlow);
		this.selected = ko.observable();

		this.records = ko.observableArray([]);

		this.table_fields = ko.observableArray([
			{
				name: 'flow_namespace'
			},
			{
				name: 'flow_name'
			},
			{
				name: 'flow_description'
			},
			{
				name: 'flow_options',
				formatter: 'json'
			}
		]);

		this.fields = ko.observableArray();
		this.visible_fields = ko.observableArray([]);
		this.visible_fields.subscribe(() => { this.update(); });

		this.filters = ko.observableArray([]);
		this.filters.subscribe(() => this.update());

		this.sortfield = ko.observable();
		this.sortorder = ko.observable('DESC');

		this.page_number = ko.observable();
		this.total_pages = ko.observable();

		this.init();
	}

	async init()
	{
		let options = {};
		if (window.localStorage.getItem('flow.flow-table.flow-list-options'))
			options = JSON.parse(window.localStorage.getItem('flow.flow-table.flow-list-options'));
		else
			options = {
				fields: ['flow_namespace','flow_name ','flow_description','flow_options']
			};

			let list = [];
			for (let field of this.table_fields())
			{
				if (!field.name)
					continue;
				let newfield = {
					name: field.name,
					visible: ko.observable(options.fields.indexOf(field.name) < 0 ? false : true)
				};

				if (field.formatter)
				{
					if (field.formatter == 'json')
						newfield.formatter = x => JSON.stringify(x);
				}

				list.push(newfield);
			}
			this.fields(list);

			if (options.sortfield)
				this.sortfield(options.sortfield);
			if (options.sortorder)
				this.sortorder(options.sortorder);
			if (options.filter)
				this.filters(options.filter);
	}

	async update()
	{
		this.fetchFlow();
	}

	async fetchFlow()
	{
		let options = this.serializeOptions();
		let result = await Grape.fetches.getJSON('/api/record', options);
		if (result.status == 'OK')
		{
			this.records(result.records);
			this.page_number(result.page_number);
			this.total_pages(result.total_pages);
		}
		else
			Grape.alerts.alert({type: 'error', message: result.message || 'Fail' });
	}

	serializeOptions()
	{
		
		let fields = ['flow_uuid','flow_namespace','flow_name','flow_description','flow_options'];
		for (let f of this.fields())
			if (f.visible())
				fields.push(f.name);

		let filters = [];
		for (let f of this.filters())
			filters.push({field: f.field, operator: f.operator, value: f.value});

		let options = {
			schema: 'states_config',
			table: 'flow',
			fields: fields,
			filter: filters
		};

		if (this.sortfield() && this.sortfield().length)
		{
			options.sortfield = this.sortfield();
			options.sortorder = this.sortorder();
		}

		window.localStorage.setItem('flow.flow-table.flow-list-options', JSON.stringify(options));

		return options;
	}

	async editFlow(data, event)
	{
		let res = await Grape.dialog.open('AddEditFlow', {flow:data, edit: true});

		if (res?.status === 'OK')
		{
			this.update();
			this.selectFlow('');
		}
	}

	selectFlow(data, event)
	{
		if (data === this.selected()) 
			this.selected(null);
		else 
			this.selected(data);

		this.selectedFlow(data);
	}

	async btn_upsert_flow()
	{
		let res = await Grape.dialog.open('AddEditFlow', {flow:{}});
		if (res?.status === 'OK')
			this.update();
	}

	async deleteFlow(data)
	{
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Flow <b>${data.flow_name}</b>?`, cancel_type:'default', accept_type:'warning'});
		if (confirm)
		{
			let res = await Grape.fetches.postJSON('/api/states-config/flow/delete', {flow_uuid: data.flow_uuid});
			console.log(res);
			if (res?.status === 'OK')
			{
				this.update();
				this.selectedFlow('');
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}
}

export default {
	name: 'flow-list',
	viewModel: FlowListViewModel,
	module_type: 'ko',
	template: template
};

